import React from 'react'
import '../Styles/environment.scss'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

function Environment() {

    return (
        <>

            <div className="environment-sec bg-img-cover parent">
                <div className="overlay">
                    <div className="environment-cont cont">
                        <h1> Environment </h1>
                    </div>
                </div>
            </div>


            <div className="environment-parent parent">
                <div className="environment-container cont">

                    <div className="environment-left">

                        <h2 className="env-title">Environment Document</h2>

                        <div className="list-container">

                            <div className='list-div'>
                                <span className='env-icon'><MdOutlineKeyboardDoubleArrowRight /></span><p>10 lakhs capital and 3 lakhs recurring for green belt development and maintenance. </p>
                            </div>


                            <div className='list-div'>
                                <span className='env-icon'><MdOutlineKeyboardDoubleArrowRight /></span><p>Local people will be prioritized for employment in the industry.</p>
                            </div>

                            <div className='list-div'>
                                <span className='env-icon'><MdOutlineKeyboardDoubleArrowRight /></span><p>Medical camps arranged in the study area under CER funds.</p>
                            </div>

                            <div className='list-div'>
                                <span className='env-icon'><MdOutlineKeyboardDoubleArrowRight /></span><p>Road development for site approach and village roads under CER activities.</p>
                            </div>

                            <div className='list-div'>
                                <span className='env-icon'><MdOutlineKeyboardDoubleArrowRight /></span><p>Excess condensate treated and recycled for process water, gardening, dust suppression.</p>
                            </div>


                        </div>
                        <div className="env-btn-div">
                            <a className='btn' href={require('../Assets/pdf/ECL_AUTH_327356_IA_MH_IND2_421345_2023.pdf')} download="Environment_Document.pdf">Download Pdf</a>
                        </div>
                    </div>

                    <div className="environment-right">
                        <div className="env-right-img bg-img-cover"></div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default Environment