import React, { useEffect, useState } from "react";
import img from "../Assets/gallery/img1.jpg"; // Ensure the path is correct
import Gallery from "../Comps/Gallery";
import "../Styles/gallery.scss";
import { fetchImages } from "../services/service";
import ImageModel from "../Comps/ImageModel";
const Gallerypage = () => {
  const [init, setInit] = useState(6);
  const [imgdata, setImgdata] = useState([]);
  const [toggle,setToggle] = useState(false)
  const [selectedid,setSelectedid] = useState("")
  const getImages = async () => {
    try {
      const response = await fetchImages();
      setImgdata(response);
    } catch (err) {}
  };
  useEffect(() => {
    getImages();
  }, []);

  const handleGalleryClick = (id) => {
    setSelectedid(id);
    setToggle(true); // Toggle the ImageModel to appear
  };

  console.log(selectedid)
  return (
    <>
    
    <div className="gallery-container gallery-parent parent">
      <div className="cont gallery-cont">
        {imgdata &&
          imgdata.slice(0,init).map((data, index) => {
            return <Gallery url={data.image} key={index}  onClick={() => handleGalleryClick(data.id)} />;
          })}
      </div>
      {init <= imgdata.length && (
        <button className="btn" onClick={() => setInit(init + 6)}>
          Load More
        </button>
      )}
    </div>
    {
   
    toggle &&  <ImageModel id={selectedid} onClose={() => setToggle(false)}/>
    }
    </>
  );
};
export default Gallerypage;
