import React from 'react';
import "../Styles/gallery.scss";

const Gallery = ({ url,onClick  }) => {
  return (
    <div 
      className="gallery-image bg-img-cover" 
      // style={{ backgroundImage: `url(${url})` }}\
      onClick={onClick} 
    >
      <img src={url} alt="" />
    </div>
  );
};

export default Gallery;
