import React, { useState } from "react";
import "../Styles/footer.scss";
import { Link } from "react-router-dom";

// icons
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { FaFacebook } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import { CiLocationOn } from "react-icons/ci";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";






function Footer() {
  const bottomTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });



  };
  return (
    <>
      <div className="swipe-top">
        <button onClick={bottomTop}>
          <MdKeyboardDoubleArrowUp />
        </button>
      </div>
      <div className="footer-parent parent">
        <div className="footer-cont cont">
          <div className="footer-logo">
            <h3>About us</h3>
            <p>
            Welcome to onkar group, with a vision of operational excellence, we stand out as a beacon of innovation across various industries. 
            </p>
            <div className="logo-name">
              <Link to="/" onClick={bottomTop}>
              <div className="footer-logo-img bg-img-contain"></div>
              </Link>
            <Link to="/" onClick={bottomTop}>
            <h2>onkar sakhar pvt. ltd.</h2>
            </Link>
            </div>
          </div>
          <div className="links">
            <h3>Links</h3>
            <div className="links-tags">
              <Link to="/about" onClick={bottomTop} >
                {" "}
                <span>
                  {" "}
                  <IoIosArrowForward />{" "}
                </span>
                About Us
              </Link>
            
              <Link to="/factories" onClick={bottomTop}>
                {" "}
                <span>
                  {" "}
                  <IoIosArrowForward />{" "}
                </span>{" "}
                Factories
              </Link>
              <Link to="/products" onClick={bottomTop}>
                {" "}
                <span>
                  {" "}
                  <IoIosArrowForward />{" "}
                </span>{" "}
                Products{" "}
              </Link>
              <Link to="/social_responsibility" onClick={bottomTop}>
                {" "}
                <span>
                  {" "}
                  <IoIosArrowForward />{" "}
                </span>
                Social responsiblity{" "}
              </Link>
              <Link to="/blogs" onClick={bottomTop} >
                {" "}
                <span>
                  {" "}
                  <IoIosArrowForward />{" "}
                </span>{" "}
                Blogs
              </Link>
            </div>
          </div>
          <div className="legal">
            <h3>Legal</h3>
            <div className="legal-tags">
              <Link to="/Privacy"  onClick={bottomTop}>
              <p> <span>
                {" "}
                <IoIosArrowForward />{" "}
              </span>  privacy policy</p>
              </Link>
              <Link to="/terms&condition"  onClick={bottomTop}>
              <p><span>
                {" "}
                <IoIosArrowForward />{" "}
              </span>  terms & condition</p>
              </Link>
            </div>
          </div>
          <div className="contact-info">
            <h3>contact info</h3>
            <div className="gmail">
              <div className="contact-icon">
                <IoIosMail />
              </div>
             <Link
             to="mailto:onkarsakharkpl.adm@gmail.com" target="blank"
             >
             <p>onkarsakharkpl.adm@gmail.com</p>
             </Link>
            </div>
            <div className="location">
              <div className="contact-icon">
                <FaLocationDot />
              </div>
             <Link 
             to="https://maps.app.goo.gl/GWKkKRfiiFGQdkZ47"
             target="blank"
             >
             <p>
             Office no. 301, Onkar Sakhar Karkhana, 3rd Floor, Fortune Business House, Sakal Nagar, Aundh, Pune

              </p>
             </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-info parent ">
        <div className="copyright cont">
          <div className="copyright-content">
            <p>
              Copyright ©2023 All Rights Reserved |{" "}
              <span>
                {" "}
                <Link to="/" onClick={bottomTop}>
                  onkar
                </Link>{" "}
              </span>{" "}
              | Designed & Developed by{" "}
              <span>
                {" "}
                <Link to="https://diwise.uk/" target="blank">
                  Diwise
                </Link>{" "}
              </span>
            </p>
          </div>

          {/* <div className="social-media-icons">
            <div className="whatsapp-icon icon">
              <IoLogoWhatsapp />
            </div>
            <div className="facebook-icon icon">
              <FaFacebook />
            </div>
            <div className="instagram-icon icon">
              <AiFillInstagram />
            </div>
            <div className="twitter-icon icon">
              <RiTwitterXFill />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Footer;
