import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const submitContactForm = async (formData) => {
  try {
    const response = await apiClient.post('/contacts/', formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};


export const fetchBlogs = async () => {
    try {
      const response = await apiClient.get('/blogs/');
      return response.data;
    } catch (error) {
      console.error('Error fetching blogs:', error);
      throw error;
    }
  };

export const fetchImages = async () => {
    try {
      const response = await apiClient.get('/images/');
      return response.data;
    } catch (error) {
      console.error('Error fetching Images:', error);
      throw error;
    }
  };


export const fetchBlogById = async (id) => {
    try {
      const response = await apiClient.get(`/blogs/${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching blog by ID:', error);
      throw error;
    }
  };
export const fetchImageById = async (id) => {
    try {
      const response = await apiClient.get(`/images/${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching Image by ID:', error);
      throw error;
    }
  };