import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { fetchBlogById, fetchImageById } from "../services/service";
const ImageModel = ({ id,onClose }) => {
  const [imagedata, setimagedata] = useState([]);
  const fetchImages = async () => {
    try {
      const response = await fetchImageById(id);
      setimagedata(response);
      console.log(response)
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchImages();
  }, []);
  console.log("image data",imagedata)
  return (
    <div className="image-model glass">
      <img
        src={imagedata.image}
        alt=""
      />
      <button className="back_btn" onClick={onClose}>
        <RxCross1 size={20} color="#000"/>
      </button>
    </div>
  );
};

export default ImageModel;
