import React, { useEffect, useState } from "react";
import "../Styles/blog.scss";
import BlogCard from "../Comps/BlogCard";
import { fetchBlogs } from "../services/service";
import useDebounce from "../hooks/useDebounce";

const Blog = () => {
  const [initialValue, setInitialValue] = useState(6);
  const [BlogData, setBlogData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  // Fetch blogs from API
  const getBlogs = async () => {
    try {
      const response = await fetchBlogs();
      setBlogData(response);
    } catch (err) {
      console.error("Error fetching blogs:", err);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  // Filter blogs based on search query
  const filteredBlogs = BlogData.filter(blog =>
    blog.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
  );

  return (
    <>
      <div className="about-sec blog-sec bg-img-cover parent">
        <div className="overlay">
          <div className="about-cont cont">
            <h1>Blogs</h1>
          </div>
        </div>
      </div>
      <div className="blog-section-parent parent">
        <div className="blog-section-cont cont">
          <div className="search">
            <input
              type="search"
              placeholder="Search Blog"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="blogs">
            <BlogCard data={filteredBlogs.slice(0, initialValue)} />
          </div>
          <div className="readmore">
            {initialValue <= filteredBlogs.length && (
              <button
                className="btn"
                onClick={() => setInitialValue(initialValue + 6)}
              >
                Load More
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
