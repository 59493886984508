import React, { useEffect, useState } from "react";
import "../Styles/home.scss";
import HeroSection from "../Comps/HeroSection";

import TwoColSec from "../Comps/TwoColSec";
import factorysecondimg from "../Assets/raw/bg2.webp";
import { Link } from "react-router-dom";
import Image from "../Assets/raw/bg3.png";
import dummyImg from "../Assets/raw/dummy-img.jpg";
// aos
import AOS from "aos";
import "aos/dist/aos.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import img from "../Assets/gallery/img1.jpg";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Gallerypage from "./Gallerypage";
import Gallery from "../Comps/Gallery";
import BlogCard from "../Comps/BlogCard";
import { fetchBlogs, fetchImages } from "../services/service";
const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 10, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.02, // 2 = 200%, 1.5 = 150%, etc..
  speed: 10, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [BlogData, setBlogData] = useState([]);
  const getBlogs = async () => {
    try {
      const response = await fetchBlogs();
      setBlogData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const [imagedata, setimagedata] = useState([]);
  const getImages = async () => {
    try {
      const response = await fetchImages();
      setimagedata(response);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getImages();
    getBlogs();
  }, []);
  return (
    <>
      <HeroSection />

      {/* <div className="about-section parent">
        <div className="about-section-cont cont">
          <div className="about-section-cont-left">
            <Tilt
              options={defaultOptions}
              className="img-box bg-img-cover"
            ></Tilt>
          </div>
          <div className="about-section-cont-right">
            <h2>About Onkar</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
              similique iusto beatae deserunt eaque iste, modi natus praesentium
              quis dolore doloremque voluptatem aspernatur ea repellat provident
              voluptatibus non! Aut, in?
            </p>

            <Link className="btn" to="/about">
              Know More
            </Link>
          </div>
        </div>
      </div>

       <ProductSwiperSection/> */}

      <TwoColSec
        background=""
        padding=""
        row=""
        bg={factorysecondimg}
        subheading="ABOUT US "
        subdescription="Welcome to the Onkar Group, a distinguished network of companies spanning the picturesque landscapes of Maharashtra. Rooted in a vision of operational excellence, the Onkar Group stands tall as an emblem of innovation across diverse sectors. Our multifaceted units embrace sustainable practices, championing responsible development. From the verdant landscapes of Solapur and Latur districts, we envision nationwide expansion, aspiring to become industry leaders.
        At Onkar Group, we embody a legacy of innovation, sustainability, and community empowerment. Join us on our journey towards a brighter, greener future.
        "
        btn="READ MORE"
      />

      <div className="two-section-parent parent">
        <div className="overlay-img bg-img-cover">
          <div className="two-section-cont cont">
            <div className="two-section-top">
              <div
                className="two-sec-right bg-img-cover"
                data-aos="fade-up"
                data-aos-duration="800"
              ></div>

              <div
                className="two-sec-left"
                data-aos="fade-up"
                data-aos-duration="900"
              >
                <h2>Factories</h2>
                <p>
                  From our main facility, Onkar Sakhar Karkhana Pvt Ltd,
                  situated in Chandapuri, Tal. Malshiras, Dist. Solapur, to our
                  subsidiary units, each boasting specialised capabilities, we
                  uphold a legacy of excellence in sugar manufacturing.
                  Leveraging our expertise and modernization initiatives, we
                  achieve remarkable daily crushing capacities, maximising
                  productivity while maintaining a commitment to operational
                  excellence.
                </p>
                <Link className="btn" to="/factories" onClick={goToTop}>
                  Know More
                </Link>
              </div>
            </div>
            <div className="two-section-bottom">
              <div
                className="two-sec-right"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h2>Products</h2>
                <p>
                  Onkar Sakhar Karkhana Pvt Ltd goes beyond just sugar
                  production. We deliver a range of products that cater to
                  agricultural and industrial needs. Our core business of sugar
                  production is supported by a commitment to sustainability
                  through cogeneration plants that produce clean energy. Our
                  reach broadens by offering high-quality industrial alcohol
                  produced in their distillery units.
                </p>
                <Link className="btn" to="/products" onClick={goToTop}>
                  Know More
                </Link>
              </div>

              <div
                className="two-sec-left bg-img-cover"
                data-aos="fade-up"
                data-aos-duration="900"
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="blog-home-parent parent">
        <div className="blog-home-cont cont">
          <h2 className="test-tag">Latest Blogs</h2>
          <div className="blogList">
            <BlogCard data={BlogData.reverse().slice(0, 3)} />
          </div>
          <Link to="/blogs" className="btn">
            View More
          </Link>
        </div>
      </div>

      <div className="gallery-home-parent parent bg-img-cover">
        <div className="gallery-home-overlay">
          <div
            className="gallery-home-cont cont bg-img-cover"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="test-tag">
              <h2>Gallery</h2>
            </div>
            <div className="home-gallery-box">
            {
              imagedata && imagedata.reverse().map((data,index)=>{
                return(
                  <Gallery url={data.image} key={index}/>

                )
              })
            }
            </div>
           
            <Link className="btn" to="/gallery">
              View More
            </Link>
          </div>
        </div>
      </div> */}

      <div className="testimoneal-parent parent">
        <div className="testimoneal-tag-cont cont">
          <div className="test-tag">
            <h2>What Our Customer Says</h2>
          </div>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 6500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="testimoneal-cont cont">
                {/* <div
                  className="testimonel-content"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></div> */}
                <div
                  className="testimoneal-para"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p>
                    As an employee at Onkar Sakhar Karkhana Pvt Ltd.'s Unit 1,
                    I've witnessed their dedication to sustainable practices
                    firsthand. They truly care about their workers and the
                    environment
                  </p>
                </div>
                <div
                  className="desgintion"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {/* <img src={dummyImg} alt="" /> */}
                  <div className="name">
                    <h5>Prem Deshmukh</h5>
                    <p> Factory Worker</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimoneal-cont cont">
                <div
                  className="testimonel-content"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></div>
                <div
                  className="testimoneal-para"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p>
                    Supplying sugarcane to Onkar Sakhar Karkhana Pvt Ltd. has
                    been a rewarding experience. Their professionalism and fair
                    practices make them stand out
                  </p>
                </div>
                <div className="desgintion">
                  {/* <img src={dummyImg} alt="" /> */}
                  <div className="name">
                    <h5>Mohan Singh</h5>
                    <p>Sugarcane Supplier</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimoneal-cont cont">
                <div
                  className="testimonel-content"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></div>
                <div
                  className="testimoneal-para"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p>
                    I've had the pleasure of partnering with Onkar Sakhar
                    Karkhana Pvt Ltd. Their dedication to excellence and
                    innovation is inspiring.
                  </p>
                </div>
                <div className="desgintion">
                  {/* <img src={dummyImg} alt="" /> */}
                  <div className="name">
                    <h5>Vikram Singhania</h5>
                    <p> Business Partner</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Home;
