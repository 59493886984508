import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchBlogById } from "../services/service";

const BlogView = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const getBlog = async () => {
    try {
      const response = await fetchBlogById(id);
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBlog();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
  return (
    <>
      <div className="about-sec blog-sec bg-img-cover parent" >
        <div className="overlay">
          <div className="about-cont cont">
            <h1>Blog Details </h1>
          </div>
        </div>
      </div>

      <div className="blog-view-parent parent">
        <div className="blog-view-cont cont">
          <div className="top">
            <div className="blogs-image bg-img-cover" style={{background : `url(${data.image})`}}>
              <div className="middle">
                <div className="date">{formatDate(data.date)}</div>
                <div className="category">{data.category && data.category.category_name}</div>
              </div>
            </div>
          </div>

          <div className="bottom">
            <h5 className="heading">{data.title}</h5>
            <p dangerouslySetInnerHTML={{__html:data.description}}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogView;
