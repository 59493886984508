import React, { useEffect } from "react";
import "../Styles/factories.scss";

import factoryImg from "../Assets/raw/bg5.webp";
import TwoColSec from "../Comps/TwoColSec";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Factories() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="about-sec bg-img-cover parent">
        <div className="overlay">
          <div className="about-cont cont">
            <h1>Our Factories </h1>
          </div>
        </div>
      </div>

      <TwoColSec
        background=""
        row=""
        bg={factoryImg}
        subheading="Onkar Sakhar Karkhana Pvt. Ltd.
        "
        subdescription="Onkar Sakhar Karkhana Pvt Ltd., situated in Chandapuri, Tal. Malshiras, Dist. Solapur, is a private limited Indian non-government company incorporated in India. Located near Malshiras, the company operates in an area abundant with irrigation facilities, particularly one-side canal irrigation, ensuring ample water availability conducive to sugarcane cultivation. Leveraging the favourable agricultural landscape, Onkar Sakhar Karkhana Pvt Ltd. specialises in sugar production, boasting an installed crushing capacity of 2500 TCD (Tons of Cane Crushed per Day). In response to the abundant supply of sugarcane in its operational area, the company has undertaken modernization initiatives, enhancing its sugar plant capabilities to achieve an impressive daily crushing capacity of 7000 metric tons. This strategic move underscores the company's commitment to operational excellence and maximising productivity in the sugar industry.


                "
        btn=""
      />

      {/* ...........//Personality Section//............. */}

      <div className="personality factory-section parent">
      <div className="overlay-img overlay-img-for-factory bg-img-cover">
   <div className="personality-container cont">
          <h2 data-aos="fade-up" data-aos-delay="0" data-aos-duration="900">
            FACTORIES
          </h2>
          <p data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
            Discover the heart of innovation and productivity at Onkar Sakhar
            Karkhana Pvt Ltd. Our factories, strategically located in key
            agricultural regions, are dedicated to excellence in sugar
            production and sustainable energy generation.
          </p>

              {/* factory 1 */}
          
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-first  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div
                  className="personality_content"
                  data-aos="fade-up"
                  data-aos-delay="0"
                  data-aos-duration="1000"
                >
                  <h3>Onkar Sakhar Karkhana Pvt. Ltd. - Unit 1 </h3>
                  <p>
                    This unit boasts a sugar daily crushing capacity of 6500
                    TCD, accompanied by a distillery producing 150 KLPD and a
                    cogeneration facility generating 20 MW of power. Situated in
                    Chandapuri, Tal. Malshiras, Dist. Solapur, the unit also
                    hosts a bio CNG project with a capacity of 12 TPH, a compost
                    fertiliser project producing 200 MT, and an Indian Oil
                    petrol pump.
                  </p>
                </div>
              </div>

               {/* factory 2 */}
      
              <div className="personality_twobox_cont"  style={{flexDirection:"row-reverse" }}>
                <div
                  className="personality_img factories-img-second  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar Sakhar Karkhana Pvt. Ltd. - Unit 2</h3>
                  <p>
                  This unit features a sugar crushing capacity of 4500 TCD, supported by a distillery producing 150 KLPD. Additionally, it includes a diesel pump, a CNG generation project, and an organic fertilizer project, ensuring a comprehensive and sustainable operational setup.
                  </p>
                </div>
              </div>

               {/* factory 3 */}
          
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-third  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar Sugar and Distillery Power Pvt. Ltd. - Unit 3</h3>
                  <p>
                  This unit is equipped with a sugar crushing capacity of 2500 TCD, complemented by a cogeneration facility generating 20 MW of electricity, ensuring efficient energy utilization and sustainability. The integrated setup supports streamlined operations and long-term productivity.
                  </p>
                </div>
              </div>

               {/* factory 4 */}
       
              <div className="personality_twobox_cont" style={{flexDirection:"row-reverse" }}>
                <div
                  className="personality_img factories-img-fourth  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Gauri Sugar and Distilleries Pvt. Ltd. - Unit 4</h3>
                  <p>
                  This unit boasts a sugar crushing capacity of 10,000 TCD, accompanied by a distillery producing 80 KLPD and a cogeneration facility generating 40 MW of electricity. Additionally, the setup includes a diesel pump, ensuring seamless operations and energy efficiency.
                  </p>
                </div>
              </div>

               {/* factory 5 */}
          
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-fifth  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar Power Corporations Pvt. Ltd. - Unit 5</h3>
                  <p>
                  This unit features a sugar crushing capacity of 6500 TCD, supported by a cogeneration facility generating 20 MW of electricity. The integrated design ensures efficient energy utilization and sustainable operations.
                  </p>
                </div>
              </div>

               {/* factory 6 */}
               <div className="personality_twobox_cont" style={{flexDirection:"row-reverse" }}>
                <div
                  className="personality_img factories-img-six bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar Sugar Corporations Pvt. Ltd. - Unit 6</h3>
                  <p>
                  This unit is equipped with a sugar crushing capacity of 6000 TCD, complemented by a distillery producing 250 KLPD and a cogeneration facility generating 20 MW of electricity. The setup ensures high efficiency and sustainable operations.
                  </p>
                </div>
              </div>              

            
              {/* factory 7 */}
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-seven bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar Sugar and Allied Industries Pvt.Ltd. - Unit 7</h3>
                  <p>
                  This unit includes a sugar crushing capacity of 2000 TCD, supported by a cogeneration facility generating 5 MW of electricity. Additionally, it features a diesel pump to facilitate seamless and efficient operations.
                  </p>
                </div>
              </div>

              {/* factory 8 */}
              <div className="personality_twobox_cont" style={{flexDirection:"row-reverse" }}>
                <div
                  className="personality_img factories-img-eight bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar Sakhar Karkhana Pvt. Ltd. - Unit 8</h3>
                  <p>
                  This unit boasts a sugar crushing capacity of 6500 TCD, accompanied by a distillery producing 75 KLPD and a cogeneration facility generating 20 MW of electricity. The setup ensures efficient and sustainable operations.
                  </p>
                </div>
              </div> 

              {/* factory 9 */}
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-nine bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar Sugar and Allied Industries Pvt. Ltd. - Unit 9</h3>
                  <p>
                  This unit features a sugar crushing capacity of 5000 TCD, supported by a distillery producing 80 KLPD. It also includes a petrol pump, a bottling project, and an organic fertilizer project, ensuring a well-rounded and sustainable operational framework.
                  </p>
                </div>
              </div>
        
        </div>
   </div>
       
       
      </div>


    </>
  );
}

export default Factories;
