import React, { useState } from "react";
import { Link } from "react-router-dom";
const BlogCard = ({ data }) => {

  function formatDate(dateString) {
    const date = new Date(dateString);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
  
  return (
    <>
      {data &&
        data.map((item, index) => (
          <Link className="card" key={index} to={`/blogs/${item.id}/`}>
            <div
              className="top bg-img-cover"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>
            <div className="middle">
              <div className="date">
                <p> {formatDate(item.date)} </p>
              </div>
              <div className="category">
                <p>{item.category.category_name}</p>
              </div>
            </div>
            <div className="bottom">
              <h5 className="heading">{item.title.slice(0,40)+"..."}</h5>
              <p dangerouslySetInnerHTML={{ __html: item.description.slice(0,150)+"..." }} />
            </div>
          </Link>
        ))}
    </>
  );
};

export default BlogCard;
