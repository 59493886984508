import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Styles/header.scss";
// icons
import Hamburger from "hamburger-react";
import { FaBars } from "react-icons/fa";

function Header() {
  const [navBar, setNavBar] = useState(false);
  const [overlay, setOverlay] = useState(false);

  const toggleOverlay = () => {
    setOverlay(!overlay);
  };
  const hideNav = () => {
    setOverlay(false);
    setNavBar(false);
  };

  const togglenavs = () => {
    setNavBar(!navBar);
  };
  const About = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };
  const Home = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };
  const Founders = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };
  const Factories = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };

  const Products = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };
  const Social = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };
  const contact = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };

  // const  Socialresponsiblity = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   setNavBar(false);
  //   setOverlay(false);
  // };

  const Environment = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setNavBar(false);
    setOverlay(false);
  };

  const address = useLocation();

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={scrolled ? "header parent scrolled" : "header parent"}>
        <div className="header-cont cont">
          <div className={scrolled ? " header-body scrolled" : "header-body"}>
            <Link to="/" className="icon" onClick={Home}>
              <div className="logo bg-img-contain">
                <h2>Onkar</h2>
              </div>
            </Link>
            <div className={!navBar ? "links" : "responNav"}>
              <Link
                className={address.pathname === "/" ? "active" : ""}
                onClick={Home}
                to="/"
              >
                Home
              </Link>
              <Link
                className={address.pathname === "/about" ? "active" : ""}
                onClick={About}
                to="/about"
              >
                About Us
              </Link>
              {/* <Link
                className={address.pathname === "/founders" ? "active" : ""}
                onClick={Founders}
                to="/founders"
              >
                Founders
              </Link> */}
              <Link
                className={address.pathname === "/factories" ? "active" : ""}
                onClick={Factories}
                to="/factories"
              >
                Factories
              </Link>

              <Link
                className={address.pathname === "/products" ? "active" : ""}
                onClick={Products}
                to="/products"
              >
                Products
              </Link>

     
              <div className="dropdown">
                <button className="dropbtn">Our Impact</button>
                <div className="dropdown-content">
                  <Link onClick={Social}
                    className={
                      address.pathname === "/social_responsibility" ? "active" : ""
                    }
                    to="/social_responsibility"
                  >
                    Social Responsibility
                  </Link>
                  <Link  onClick={Environment}
                    className={
                      address.pathname === "/environment" ? "active" : ""
                    }
                    to="/environment"
                  >
                    Environment
                  </Link>
                </div>
              </div>
              {/* <Link
                className={address.pathname === "/blogs" ? "active" : ""}
                onClick={Products}
                to="/blogs"
              >
                Blogs
              </Link>
              <Link
                className={address.pathname === "/gallery" ? "active" : ""}
                onClick={Products}
                to="/gallery"
              >
                Gallery
              </Link> */}

              <Link to="/contact" onClick={contact} className="btn">
                Contacts Us
              </Link>
            </div>
            <span className="nav-icon" onClick={toggleOverlay}>
              <Hamburger toggled={navBar} toggle={togglenavs} />
            </span>
          </div>
        </div>
      </div>

      {overlay && <div className="nav-overlay" onClick={hideNav}></div>}
    </>
  );
}

export default Header;
